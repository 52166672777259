import React from 'react';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick/lib/slider';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleProduct from '../SingleProduct';
import Footer from '../Footer';
import Nav from '../Nav';

const products = [
  // {
  //   id: 'yenechinet',
  //   name: 'Yene Chinet',
  //   url: 'https://web.yenechinet.com/',
  //   description: `Yene Chinet is a Transport and Fleet Management system platform designed to alleviate the complexities in the Logistics Industry particularly on Road freight in
  //     Ethiopia. Yene Chinet will help integrate key market
  //     players to have a transparent and uptodate information
  //     which is useful for a timely action and decision making.
  //     Yene Chinet contains two major portals designed to
  //     connect the supply chain. The First one is the
  //     Transporter Portal and the other one is the Shipper
  //     Portal.`,
  //   shortDescription: `Yene Chinet is a Transport and Fleet Management system platform designed to alleviate the complexities in the Logistics Industry.`,
  //   category: 'SAAS',
  //   coverImage: `img/yenechinetcoverimage.jpg`,
  // },
  {
    id: 'primeproperty',
    name: 'Prime Property & Logistics',
    url: 'https://primepropertiesandlogistics.com',
    category: 'Web',
    coverPage: '/img/prime-property.png',
    shortDescription:
      'Prime Property Management and Logistics Solutions (PPMLS) is one of the emerging services providing company in Ethiopia, founded in September 2023',
    description: `Prime Property Management and Logistics Solutions (PPMLS) is one of the emerging services providing company in Ethiopia, founded in September 2023, involved in the fields of providing specialty services on Property Management and Supply chain operations.`,
  },
  {
    id: 'property-management',
    name: 'Property Management (PMS)',
    description: `
    Property management system (PMS), also known as rental property management software, is designed to simplify property management tasks and automate various processes. Whether you’re managing residential or commercial properties, a PMS can be incredibly beneficial.
   Key Aspects of Property Management Systems:
  
        Accounting: PMS software streamlines financial tasks such as rent collection, expense tracking, and generating financial reports. It saves time and ensures accurate bookkeeping.
        Maintenance Management: PMS helps you organize maintenance requests, schedule repairs, and track work orders. It ensures timely maintenance and keeps properties in good condition.
        Online Payments: Tenants can conveniently pay rent online through the system. This feature reduces paperwork and simplifies payment processing.
        Document Management: PMS allows you to store and manage lease agreements, inspection reports, and other property-related documents digitally. No more digging through physical files!
        Tenant Screening: You can screen potential tenants by checking their credit history, criminal records, and rental history. This helps you make informed decisions.
        Communications: PMS facilitates communication with tenants via email, text messages, or notifications. You can send announcements, reminders, and updates efficiently.
  `,
    shortDescription: `PMS is designed to simplify property management tasks and automate various processes. Whether you are managing residential or commercial properties, it can be incredibly beneficial.`,
    category: 'WEB',
    coverPage: '/img/property-management.jpeg',
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SampleNextArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SamplePrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ProjectStatic = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    cssEase: 'linear',
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {currentPath === '/projects' ? (
        <>
          <Nav />
          {/* <!-- Page Header Start --> */}
          <div className='container-fluid page-header py-5'>
            <div className='container text-center py-5'>
              <h1 className='display-2 text-white mb-4 animated slideInDown'>
                {t(`Projects`)}
              </h1>
              <nav aria-label='breadcrumb animated slideInDown'>
                <ol className='breadcrumb justify-content-center mb-0'>
                  <li className='breadcrumb-item'>
                    <a href='#'>{t(`Home`)}</a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a href='#'>
                      {t(`Pages`, {
                        ns: 'components_header_trn',
                      })}
                    </a>
                  </li>
                  <li className='breadcrumb-item' aria-current='page'>
                    {t(`Projects`)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* <!-- Page Header End --> */}
        </>
      ) : null}

      {/* <!-- Project Start --> */}
      <div className='container-fluid products py-3 mb-4'>
        <div className='container'>
          <div
            className='text-center mx-auto pb-5 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '700px' }}
          >
            <h5 className='txt-primary'>
              {t(`Our Project`, {
                ns: 'components_header_trn',
              })}
            </h5>
            <h1>
              {t(`Our Recently Completed Projects`, {
                ns: 'components_header_trn',
              })}
            </h1>
          </div>
          <div
            className='slider-container products-carousel wow fadeIn'
            data-wow-delay='.5s'
          >
            <Slider {...settings}>
              {products?.map((product, index) => (
                <SingleProduct key={index} product={product} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* <!-- Project End --> */}
      {currentPath === '/projects' ? <Footer /> : null}
    </>
  );
};

export default ProjectStatic;
