import { useTranslation } from 'react-i18next';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { useLocation, useNavigate } from 'react-router-dom';
import ServiceStatic from '../components/static-component/service';
// import useSWR from 'swr';
// import fetcher from '../baseQuery/fetcher';

const Service = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    // top: 10,
    // skip: 0,
    locale: currentLanguage,
  };

  const {
    data: servicesData,
    loading,
    error,
  } = useAxiosQuery({
    url:
      currentPath === '/'
        ? '/portal-product-and-services/get-services?top=6'
        : '/portal-product-and-services/get-services',
    method: 'GET',
    params: collection,
  });

  if (error) {
    return <ServiceStatic />;
    // return <h1>hi all</h1>;
  }

  return (
    <>
      {/* <!-- Page Header Start --> */}
      {currentPath === '/services' ? (
        <>
          <Nav />
          <div className='container-fluid page-header py-5'>
            <div className='container text-center py-5'>
              <h1 className='display-2 text-white mb-4 animated slideInDown'>
                {t(`Services`)}
              </h1>
              <nav aria-label='breadcrumb animated slideInDown'>
                <ol className='breadcrumb justify-content-center mb-0'>
                  <li className='breadcrumb-item'>
                    <a href='#'>{t(`Home`)}</a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a href='#'>
                      {t(`Pages`, {
                        ns: 'components_header_trn',
                      })}
                    </a>
                  </li>
                  <li className='breadcrumb-item' aria-current='page'>
                    {t(`Services`)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </>
      ) : null}
      {/* <!-- Page Header End --> */}

      {/* <Stat /> */}

      {/* <!-- Services Start --> */}
      <div className='container-fluid services py-2 my-5'>
        <div className='container py-2'>
          <div
            className='text-center mx-auto pb-5 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='txt-primary'>
              {t(`Our Services`, {
                ns: 'components_header_trn',
              })}
            </h5>
            <h1>
              {t(`Our Service that we provide for your business`, {
                ns: 'components_header_trn',
              })}
            </h1>
          </div>

          {/* <!-- dynamic Services Inner Start --> */}
          <div className='row g-5 services-inner'>
            {servicesData?.data?.map((service) => (
              <div
                key={service?.id}
                className='col-md-6 col-lg-4 wow fadeIn g-5 services-inner'
                data-wow-delay='.3s'
              >
                <div className='services-item bg-light'>
                  <div className='p-4 text-center services-content'>
                    <div className='services-content-icon'>
                      <img
                        width={130}
                        height={100}
                        src={service?.coverPage}
                        alt='icon'
                      />
                      <h4 className='mt-3 mb-3'>{service?.name}</h4>
                      <p className='mb-4'>{service?.description}</p>
                      {/* <a
                      href=""
                      className="btn btn-primary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {currentPath === '/' &&
              !loading &&
              servicesData?.data?.length > 0 && (
                <div>
                  <button
                    type='button'
                    className='btn btn-primary text-white px-5 py-3 rounded-pill'
                    style={{ justifyContent: 'center' }}
                    onClick={() => {
                      navigate('/services');
                    }}
                  >
                    {t(`See More`, {
                      ns: 'components_header_trn',
                    })}
                  </button>
                </div>
              )}
          </div>

          {/* <!-- dynamic Services Inner End --> */}
        </div>
      </div>
      {/* <!-- Services End --> */}

      {currentPath === '/services' ? <Footer /> : null}
    </>
  );
};

export default Service;
