import Slider from 'react-slick/lib/slider';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';

const OurPartner = () => {
  const { t } = useTranslation();
  // const { i18n } = useTranslation();
  // const currentLanguage = i18n.language;

  const collection = {
    // locale: currentLanguage,
  };

  const { data: partnerData } = useAxiosQuery<{ data: any[] }>({
    url: '/portal-customer/get-partners',
    method: 'GET',
    params: collection,
  });

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className='container-fluid services py-4 mb-4'>
        <div className='container'>
          {partnerData && (
            <div
              className='text-center mx-auto pb-4 wow fadeIn'
              data-wow-delay='.3s'
              style={{ maxWidth: '600px', marginTop: '10px' }}
            >
              <h5
                className='txt-primary text-decoration-underline'
                style={{ textUnderlineOffset: '0.5rem' }}
              >
                {t(`Our Partners`, { ns: 'components_header_trn' })}
              </h5>
              {/* <h1>
              {t(`We have delivered and trusted by multiple partners`, {
                ns: 'components_header_trn',
              })}
            </h1> */}
            </div>
          )}
          <div className='row g-5 services-inner'>
            <div className='slider-container'>
              <Slider {...settings}>
                {partnerData &&
                  partnerData?.data?.map((partner) => (
                    <div
                      className='col-lg-4 col-md-6 wow fadeInUp'
                      data-wow-delay='.1s'
                      key={partner.id}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        className='client-img'
                        title={partner.name}
                        src={
                          partner.logo ? partner.logo : '/img/defalt logo.png'
                        }
                        alt={partner.name}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPartner;
