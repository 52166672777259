import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { ResponseType } from 'axios';
import axiosBaseQuery from './axiosbasequery';

interface QueryArgs {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'; // Add other methods as needed
  data?: any;
  headers?: Record<string, string>;
  params?: Record<string, any>;
  responseType?: ResponseType;
}

interface Error {
  status?: number;
  data?: any;
}

interface UseAxiosQueryResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
}

const cache: Record<string, any> = {};
const pendingRequests: Record<string, Promise<any> | undefined> = {};

const useAxiosQuery = <T,>(queryArgs: QueryArgs): UseAxiosQueryResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const isMounted = useRef(true);

  const memoizedQueryArgs = useMemo(
    () => ({
      url: queryArgs.url,
      method: queryArgs.method,
      data: queryArgs.data,
      params: queryArgs.params,
      headers: queryArgs.headers,
      responseType: queryArgs.responseType,
    }),
    [
      queryArgs.url,
      queryArgs.method,
      queryArgs.data,
      queryArgs.params,
      queryArgs.headers,
      queryArgs.responseType,
    ]
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    const cacheKey = JSON.stringify(memoizedQueryArgs);

    if (cache[cacheKey]) {
      setData(cache[cacheKey]);
      setLoading(false);
      return;
    }

    if (pendingRequests[cacheKey] !== undefined) {
      try {
        const response = await pendingRequests[cacheKey];
        if (isMounted.current) {
          setData(response.data || null);
          setError(response.error || null);
        }
      } finally {
        setLoading(false);
      }
      return;
    }

    const defaultBaseUrl = import.meta.env.VITE_PUBLIC_APP_API;

    const query = axiosBaseQuery({
      baseUrl: defaultBaseUrl,
    });

    pendingRequests[cacheKey] = query(memoizedQueryArgs);

    try {
      const response = await pendingRequests[cacheKey];
      if (isMounted.current) {
        if (response.data) {
          cache[cacheKey] = response.data;
          setData(response.data as T);
        } else {
          setError(response.error || { data: 'An error occurred' });
        }
      }
    } catch (err) {
      if (isMounted.current) {
        setError({ data: 'An error occurred' });
      }
    } finally {
      delete pendingRequests[cacheKey];
      setLoading(false);
    }
  }, [memoizedQueryArgs]);

  useEffect(() => {
    isMounted.current = true;
    let didCancel = false;

    fetchData();

    return () => {
      didCancel = true;
      isMounted.current = false;
    };
  }, []);
  // }, [fetchData]);

  return { data, loading, error };
};

export default useAxiosQuery;
