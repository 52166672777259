import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from '../../utils/Avatar';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SampleNextArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SamplePrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const TestimonialStatic = () => {
  const { t } = useTranslation();

  const handleNextClick = () => {
    // Add your logic here to handle the next click event
  };
  const handlePrevClick = () => {
    // Add your logic here to handle the next click event
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: (
      <SampleNextArrow
        className='next-arrow'
        style={{ left: '50%' }}
        onClick={handleNextClick}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        className='prev-arrow'
        style={{ left: '50%' }}
        onClick={handlePrevClick}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonial = [
    {
      name: 'Samuel A.',
      cover: '',
      rating: 5,
      message:
        'Kachamale Technology provided us with a tailored software solution that revolutionized how we manage our inventory and customer relationships. ',
    },
    {
      name: 'Abel M.',
      cover: '',
      rating: 4.5,
      message:
        'Kachamale Technology didn’t just develop software for us; they became a partner in our journey toward success.',
    },
    {
      name: 'Lidya Y.',
      cover: '',
      rating: 5,
      message:
        'From initial consultation to final deployment, Kachamale Technology was with us every step of the way. ',
    },
  ];

  return (
    <div className='container-fluid testimonial py-2 mb-2'>
      <div className='container'>
        <div
          className='text-center mx-auto pb-5 wow fadeIn'
          data-wow-delay='.3s'
          style={{ maxWidth: '600px' }}
        >
          <h5 className='txt-primary'>
            {t(`Our Testimonial`, { ns: 'components_header_trn' })}
          </h5>
          <h1>{t(`Our Client Saying!`, { ns: 'components_header_trn' })}</h1>
        </div>
        <div className='testimonial-carousel wow fadeIn' data-wow-delay='.5s'>
          <Slider {...settings}>
            {testimonial &&
              testimonial?.map((item, index) => (
                <div
                  className='testimonial-item border p-4'
                  key={index}
                  style={{ height: '650px' }}
                >
                  <div className='d-flex align-items-center'>
                    <div className='border-circle'>
                      {item?.cover ? (
                        <img
                          src={item.cover}
                          alt='Semret Hailemariam Picture'
                          width={80}
                          height={80}
                          className='img-fluid  bg-light rounded-circle'
                          style={{ objectFit: 'cover' }}
                          loading='lazy'
                        />
                      ) : (
                        <Avatar Name={item.name} />
                      )}
                    </div>
                    <div className='ms-4'>
                      <h4 className='text-primary'>{item.name}</h4>
                      {/* <p className="m-0 pb-3">Profession</p> */}
                      <div className='d-flex pe-5'>
                        {item.rating && (
                          <>
                            {Array(5)
                              .fill(null)
                              .map((_, i) => (
                                <i
                                  key={i}
                                  className={`fas fa-star me-1 ${
                                    i < item.rating ? 'txt-primary' : ''
                                  }`}
                                ></i>
                              ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='border-top mt-4 pt-3'>
                    <p className='m-0'>{item.message}</p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialStatic;
