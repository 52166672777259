import React from 'react';
import { useTranslation } from 'react-i18next';

const WhyUsStatics = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const whyUsData = [
    {
      title: 'Expertise in Tailored Software Solutions',
      description:
        ' Our team of experts combines local knowledge with cutting-edge technology to deliver innovative and efficient systems that drive growth and operational excellence.',
    },
    {
      title: 'Commitment to Quality and Reliability',
      description:
        'We prioritize quality and reliability in every project. Our solutions are designed to be scalable, secure, and user-friendly, ensuring long-term success for our clients. ',
    },
    {
      title: 'Empowering Businesses with Technology',
      description:
        'Kachamale Software Solution empowers Ethiopian businesses by leveraging the latest advancements in software development. ',
    },
  ];

  return (
    <>
      <div className='container-fluid services py-4 mb-4'>
        <div className='container'>
          <div
            className='text-center mx-auto pb-4 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='txt-primary mb-4'>
              {t(`Why Choose Us`, {
                ns: 'components_header_trn',
              })}
            </h5>
          </div>

          <div className='row g-5'>
            {whyUsData?.map((data, index) => (
              <div className='col-md-4 col-lg-4 g-5' key={index}>
                <div className='d-flex'>
                  <div>
                    <i className='fas fa-bullet text-primary me-2'></i>
                  </div>
                  <div>
                    <h4>{data?.title}</h4>
                  </div>
                </div>
                <p className='indent-8 text-start text-wrap w-10'>
                  {data?.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUsStatics;
