import React from 'react';
import Nav from '../Nav';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';

const ServiceStatic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const servicesData = [
    {
      title: 'Website and Web Design development',
      description:
        'Cutting-edge web solutions tailored to your needs, from sleek designs to seamless functionality, we bring your online vision to life.',
      icon: 'fa fa-code',
    },
    {
      title: 'Software Development & Implementation',
      description:
        'From concept to execution, our software development and implementation services ensure seamless solutions tailor to your business needs.',
      icon: 'fa fa-file-code',
    },

    {
      title: 'IT & Business Consultancy',
      description:
        'Elevating Business through Strategic IT Guidance: Consultancy Solutions for Sustainable Growth.',
      icon: 'fa fa-users',
    },
    {
      title: 'System Integration and Outsourcing Services ',
      description:
        'Efficiently integrate systems and outsource tasks for streamlined operations and cost savings.',
      icon: 'fas fa-cogs',
    },
    {
      title: 'Cloud Infrastructure and Hosting Services  ',
      description:
        'We Have Reliable cloud infrastructure and hosting services for seamless scalability and optimal performance.',
      icon: 'fa fa-cloud',
    },
    {
      title: 'UI/UX and Graphics Design  ',
      description:
        'We Provide Crafting captivating UI/UX and graphic designs to elevate digital experiences and captivate audiences.',
      icon: 'fas fa-laptop',
    },
    {
      title: 'Search Engine Optimization (SEO)',
      description:
        ' Optimize your online presence with our SEO service for maximum visibility and growth',
      icon: 'fas fa-globe',
    },
    {
      title: 'ERP Development & Implementation',
      description:
        'We serve you in Tailored ERP solutions developed and implemented seamlessly for optimized business processes and enhanced efficiency.',
      icon: 'fas fa-cash-register',
    },
  ];

  return (
    <>
      {/* <!-- Page Header Start --> */}
      {currentPath === '/services' ? (
        <>
          <Nav />
          <div className='container-fluid page-header py-5'>
            <div className='container text-center py-5'>
              <h1 className='display-2 text-white mb-4 animated slideInDown'>
                {t(`Services`)}
              </h1>
              <nav aria-label='breadcrumb animated slideInDown'>
                <ol className='breadcrumb justify-content-center mb-0'>
                  <li className='breadcrumb-item'>
                    <a href='#'>{t(`Home`)}</a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a href='#'>
                      {t(`Pages`, {
                        ns: 'components_header_trn',
                      })}
                    </a>
                  </li>
                  <li className='breadcrumb-item' aria-current='page'>
                    {t(`Services`)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </>
      ) : null}
      {/* <!-- Page Header End --> */}

      {/* <!-- Services Start --> */}
      <div className='container-fluid services py-2 my-5'>
        <div className='container py-2'>
          <div
            className='text-center mx-auto pb-5 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='txt-primary'>Our Services</h5>
            <h1>Our Service that we provide for your business</h1>
          </div>
          <div className='row g-5 services-inner'>
            {servicesData
              ?.slice(0, currentPath === '/' ? 6 : servicesData.length)
              .map((service) => (
                <div
                  key={service.title}
                  className='col-md-6 col-lg-4 wow fadeIn'
                  data-wow-delay='.3s'
                >
                  <div className='services-item bg-light'>
                    <div className='p-4 text-center services-content'>
                      <div className='services-content-icon'>
                        <i
                          className={`${service.icon} fa-7x mb-4 txt-primary`}
                        ></i>
                        <h4 className='mb-3'>{service.title}</h4>{' '}
                        <p className='mb-4'>{service.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {currentPath === '/' && servicesData?.length > 6 && (
              <div>
                <button
                  type='button'
                  className='btn btn-primary text-white px-5 py-3 rounded-pill'
                  style={{ justifyContent: 'center' }}
                  onClick={() => {
                    navigate('/services');
                  }}
                >
                  {t(`See More`, {
                    ns: 'components_header_trn',
                  })}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {currentPath === '/services' ? <Footer /> : null}
    </>
  );
};

export default ServiceStatic;
