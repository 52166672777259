import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import Avatar from '../utils/Avatar';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TestimonialStatic from './static-component/testimonials';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SampleNextArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SamplePrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Testimonial = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    locale: currentLanguage,
  };

  const { data: testimonial, error } = useAxiosQuery({
    url: '/portal-interactions/get-testimonials',
    method: 'GET',
    params: collection,
  });

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (error) {
    return <TestimonialStatic />;
  }

  return (
    <div className='container-fluid testimonial py-2 mb-2'>
      <div className='container'>
        <div
          className='text-center mx-auto pb-5 wow fadeIn'
          data-wow-delay='.3s'
          style={{ maxWidth: '600px' }}
        >
          <h5 className='txt-primary'>
            {t(`Our Testimonial`, { ns: 'components_header_trn' })}
          </h5>
          <h1>{t(`Our Client Saying!`, { ns: 'components_header_trn' })}</h1>
        </div>
        <div className='testimonial-carousel wow fadeIn' data-wow-delay='.5s'>
          <Slider {...settings}>
            {testimonial &&
              testimonial?.data?.map((item, index) => (
                <div
                  className='testimonial-item border p-4'
                  key={index}
                  style={{ height: '550px' }}
                >
                  <div className='d-flex align-items-center'>
                    <div className='border-circle'>
                      {item?.cover ? (
                        <img
                          src={item.cover}
                          alt='Semret Hailemariam Picture'
                          width={80}
                          height={80}
                          className='img-fluid  bg-light rounded-circle'
                          style={{ objectFit: 'cover' }}
                          loading='lazy'
                        />
                      ) : (
                        <Avatar Name={item.name} />
                      )}
                    </div>
                    <div className='ms-4'>
                      <h4 className='text-primary'>{item.name}</h4>
                      {/* <p className="m-0 pb-3">Profession</p> */}
                      <div className='d-flex pe-5'>
                        {item.rating && (
                          <>
                            {Array(5)
                              .fill()
                              .map((_, i) => (
                                <i
                                  key={i}
                                  className={`fas fa-star me-1 ${
                                    i < item.rating ? 'txt-primary' : ''
                                  }`}
                                ></i>
                              ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='border-top mt-4 pt-3'>
                    <p className='m-0'>{item.message}</p>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
